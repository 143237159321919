import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2024 = () => (
  <Layout
    title="3M 2024 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2024 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2024 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: '25f96ef3-9a23-414c-8dca-1e983df88294',
                    poster: '2024-finalist-thumb-aakash-manaswi.png',
                    title: '3M 2024 YSC Finalist Presentation: Aakash Manaswi',
                    description:
                      'Meet Aakash. Aakash developed a natural treatment to save honeybee hives from being destroyed by the Varroa Mite.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '589e5de5-9ec2-4ceb-a799-28d4585d95e7',
                    poster: '2024-finalist-thumb-ankan-das.png',
                    title: '3M 2024 YSC Finalist Presentation: Ankan Das',
                    description:
                      'Meet Ankan. Ankan created a mobile robot whose overall motion is generated by simple motions without having to use motors or gears.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '44c97492-4abb-4612-b4c0-b5dff437b9e0',
                    poster: '2024-finalist-thumb-hanna-suzuki.png',
                    title: '3M 2024 YSC Finalist Presentation: Hanna Suzuki',
                    description:
                      'Meet Hanna. Hanna created an AR experience by using constellation sonifer to scan AR markers that would identify the data through sound.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'fdcbeefd-b741-442e-8624-12cc86f760de',
                    poster: '2024-finalist-thumb-minula-weerasekera.png',
                    title:
                      '3M 2024 YSC Finalist Presentation: Minula Weerasekera',
                    description:
                      'Meet Minula. Minula created a solution for storing energy for longer through organic compounds and a sulfur-based terhiophene.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '635099fc-85b6-4464-8fb5-f7b592cb72ec',
                    poster: '2024-finalist-thumb-prince-nallamothula.png',
                    title:
                      '3M 2024 YSC Finalist Presentation: Prince Nallamothula',
                    description:
                      'Meet Prince. Prince created an innovative way to enhance the efficiency of Quantum Dot Solar Cells through quantum chemistry to combat CO2 pollution worldwide.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '6e7731e5-2025-4f86-a354-1c0a4ba4a2a5',
                    poster: '2024-finalist-thumb-rithvik-suren.png',
                    title: '3M 2024 YSC Finalist Presentation: Rithvik Suren',
                    description:
                      'Meet Rithvik. Rithvik is creating a method to predict earthquakes using AI technology and identify sequences that will better forecast events in the future.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'f0d8453b-d72e-4877-9414-bed66b1f91af',
                    poster: '2024-finalist-thumb-ronita-shukla.png',
                    title: '3M 2024 YSC Finalist Presentation: Ronita Shukla',
                    description:
                      'Meet Ronita. Ronita developed an alternative approach to absorb atmospheric carbon dioxide by increasing the rate of photosynthesis with spirulina (blue green algae).',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'f25c07cf-b916-46f3-b395-ed5b658be999',
                    poster: '2024-finalist-thumb-sirish-subash.png',
                    title: '3M 2024 YSC Finalist Presentation: Sirish Subash',
                    description:
                      'Meet Sirish. Sirish created a method to detect pesticide residue on consumable produce using an AI based handheld detector.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '9046abdf-6375-42b5-a786-e35f06be6541',
                    poster: '2024-finalist-thumb-steven-goodman.png',
                    title: '3M 2024 YSC Finalist Presentation: Steven Goodman',
                    description:
                      "Meet Steven. Steven's innovation is ProkODE, a software that uses bioinformatics to predict how bacteria and archaea (prokaryotes) will interpret their static genomes into dynamic gene expression throughout development.",
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'bc5341fb-bce9-4809-8d2a-e3ef11ccba65',
                    poster: '2024-finalist-thumb-william-tan.png',
                    title: '3M 2024 YSC Finalist Presentation: William Tan',
                    description:
                      'Meet William. William created an AI Smart Artificial Reef that encourages coral, seashells, kelp and other marine life to grow in a safe and controlled environment.',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2020-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2024 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2024;
