import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import OutboundLink from '../../components/OutboundLink';
import Image from '../../components/Image';
import Icon from '../../components/Icon';
import Accordion from '../../components/Accordion';
import StudentQuote from '../../components/StudentQuote';

const AboutTheChallenge = () => (
  <Layout title="About The Challenge" className="annual-challenge">
    {/* Convert to proper Hero Component */}
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-orange"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../annual-challenge/about-the-challenge/">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb active">About The Challenge</li>
        </ul>
        <h1 className="page-title">About The Challenge</h1>
        <Image filename="hero-about-challenge.png" className="hero-image" />
      </div>
    </div>
    {/* Annual Video Competition */}
    <Section className="video-competition">
      <Container>
        <Row>
          <Column size={12}>
            <h2>Annual Video Competition</h2>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <Image filename="image-video-competition.jpg" />
          </Column>
          <Column size={6} className="pb-5">
            <h4 className="global-intro-text pr-7">
              The 3M Young Scientist Challenge gives students in grades 5–8 the
              chance to change their world for the better with a single
              innovative idea. This premier science competition is a
              one-of-a-kind project-based learning opportunity designed to spark
              creativity, collaboration, and connection for students from every
              background.
            </h4>
            <a
              href="https://dlc.com/3MYSC-Application"
              target="_blank"
              className="cta__link no-arrow bg-gradient-orange"
            >
              Enter Today <Icon name="arrowright" />
            </a>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Entering the Challenge */}

    <Section className="competition-entry-steps">
      <Container>
        <Row className="mb-2">
          <Column size={8}>
            <h2>Entering the Challenge</h2>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists?years=2024">
              <h4 className="mb-1">
                Meet the Finalists of the 2024 3M Young Scientist Challenge!
              </h4>
            </Link>
            <p>
              Inspire scientific exploration and innovation in any learning
              environment with comprehensive challenge resources that guide
              students through the submission process.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="box-shadow hover">
              <div className="flex p-1.5 pb-0 mb-1">
                <span className="info-box__number light-orange">1</span>
                <h3 className="light-orange mt-3">Get Started</h3>
              </div>
              <div className="p-2 pt-0 pb-6">
                <p className="p-22 text-bold">
                  Kickstart student entries with easy-to-use challenge tools.
                </p>
                <p>
                  Review challenge details below and get started by registering
                  students. You’ll receive email updates about upcoming
                  deadlines and helpful challenge tips.
                </p>
              </div>
              <OutboundLink
                to="https://dlc.com/3MYSC-Application"
                className="cta__link bg-gradient-orange-one"
              >
                Register Now <Icon name="arrowright" />
              </OutboundLink>
            </div>
          </Column>
          <Column size={4}>
            <div className="box-shadow hover">
              <div className="flex p-1.5 pb-0 mb-1">
                <span className="info-box__number pr-1 orange">2</span>
                <h3 className="orange mt-3">Ideate</h3>
              </div>
              <div className="p-2 pt-0 pb-6">
                <p className="p-22 text-bold">
                  Tackle real-world challenges that students care about.
                </p>
                <p>
                  Use the Entry Topics to hone-in on an issue that is meaningful
                  to students, along with a variety of other challenge resources
                  that provide a flexible framework to facilitate student
                  submissions.
                </p>
              </div>
              <Link
                to="/annual-challenge/entry-topics"
                className="cta__link bg-gradient-orange-two"
              >
                Challenge Entry Topics <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="box-shadow hover">
              <div className="flex p-1.5 pb-0 mb-1">
                <span className="info-box__number pr-1 red">3</span>
                <h3 className="red mt-3">Submit</h3>
              </div>
              <div className="p-2 pt-0 pb-6">
                <p className="p-22 text-bold">
                  Ensure student submissions are ready to go.
                </p>
                <p className="mb-1.5">
                  After students have created their 1-2 minute video, make sure
                  that they’re all set to submit by reviewing the Challenge
                  Checklist.
                </p>
              </div>
              <OutboundLink
                to="https://dlc.com/3MYSC-Application"
                className="cta__link bg-gradient-orange-three"
              >
                Finalize Student Entries <Icon name="arrowright" />
              </OutboundLink>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Challenge Details */}
    <Section className="challenge-details">
      <Container>
        <Row className="mb-2">
          <Column size={7}>
            <h2>Challenge Details</h2>
            <p>
              The 3M Young Scientist Challenge invites students to create an
              original solution to an existing problem for the chance to win a
              3M mentorship and more.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4} className="info-box no-bg pb-5">
            <div className="pt-2 pr-0.5 pb-2 pl-0">
              <Image
                filename="icon-rules-and-prizes.svg"
                className="challenge-details__icon"
              />
              <h3 className="h-large">Rules & Prizes</h3>
              <ul className="challenge-details__list">
                <li>
                  <h3>Grand Prize Winner $25,000</h3>
                  <p>Title of “America’s Top Young Scientist”</p>
                </li>
                <li>
                  <h3>Top 10 Finalists $1,000</h3>
                  <p>Unique Summer Mentorship with a 3M Scientist</p>
                </li>
                <li>
                  <h3>State Merit Winners</h3>
                  <p>
                    (up to 51 awarded) <br />
                    3M Young Scientist Challenge Prize Packs & Certificate
                  </p>
                </li>
                <li>
                  <h3>Honorable Mention Winners</h3>
                  <p>
                    (one per grade awarded)
                    <br />
                    Certificate
                  </p>
                </li>
              </ul>
            </div>
            <OutboundLink
              to="https://dlc.com/3MYSC-rules"
              className="cta__link no-arrow bg-gradient-orange-one"
            >
              View Rules <Icon name="arrowright" />
            </OutboundLink>
          </Column>
          <Column size={4} className="info-box no-bg pb-5">
            <div className="pt-2 pr-0.5 pb-2 pl-0.5">
              <Image
                filename="icon-timeline.svg"
                className="challenge-details__icon"
              />
              <h3 className="h-large">Timeline</h3>
              <ul className="challenge-details__list timeline">
                <li>
                  <h3>Challenge Opens</h3>
                  <p>January 8, 2025</p>
                </li>
                <li>
                  <h3>Challenge Closes</h3>
                  <p>May 1, 2025</p>
                </li>
                <li>
                  <h3>Finalist and State Merit Winner Announcements</h3>
                  <p>June 2025</p>
                </li>
                <li>
                  <h3>Final Event</h3>
                  <p>October 2025</p>
                </li>
                <li>
                  <h3>Winners Announced</h3>
                  <p>October 2025</p>
                </li>
              </ul>
            </div>
            <Link
              to="/annual-challenge/challenge-resources"
              className="cta__link no-arrow bg-gradient-orange-two"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
          <Column size={4} className="info-box no-bg pb-5">
            <div className="pt-2 pr-0 pb-2 pl-0">
              <Image
                filename="icon-judging.svg"
                className="challenge-details__icon"
              />
              <h3 className="h-large">Video Judging</h3>
              <ul className="challenge-details__list">
                <li>
                  <h3>Creativity (30% of Score)</h3>
                  <p>Ingenuity and innovative thinking.</p>
                </li>
                <li>
                  <h3>Scientific Knowledge (30% of Score)</h3>
                  <p>Apply STEM principles to real world challenges.</p>
                </li>
                <li>
                  <h3>
                    Persuasiveness and Effective Communication (20% of Score)
                  </h3>
                  <p>Demonstrate passion and research.</p>
                </li>
                <li>
                  <h3>Overall Presentation (20% of Score)</h3>
                  <p>Connect all the dots to inspire others.</p>
                </li>
              </ul>
            </div>
            <OutboundLink
              to="https://info.discoveryeducation.com/YoungScientistLab.html"
              className="cta__link icon--mail bg-gradient-orange-three"
            >
              Sign up for updates
              <Icon name="signup" className="ml-0.5" />
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Frequently Asked Questions */}
    <Section className="about-faqs">
      <Container>
        <Row>
          <Column size={12}>
            <h2 className="mb-0.5">Frequently Asked Questions</h2>
            <p className="text-bold">
              Peruse these common queries about entering the challenge.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <div className="about-info__accordion mr-2">
              <div className="accordion">
                <div className="accordion__group">
                  <div className="accordion__item">
                    <input type="checkbox" id="chck1"></input>
                    <label className="label" for="chck1">
                      How do students enter?
                    </label>
                    <div className="content">
                      To enter, students need to submit a 1-2 minute video in
                      which they describe a new invention or solution that could
                      solve or impact an everyday problem that directly affects
                      them, their families, their communities, and/or the global
                      population. Get ideas from this year’s&nbsp;
                      <Link
                        to="/annual-challenge/entry-topics"
                        className="link"
                      >
                        entry topics
                      </Link>
                      .
                    </div>
                  </div>
                  <div className="accordion__item">
                    <input type="checkbox" id="chck2"></input>
                    <label className="label" for="chck2">
                      How are videos judged?
                    </label>
                    <div className="content">
                      Videos do NOT need to be "produced" or have high
                      production value. Judges are not evaluating production
                      skills. Videos may be recorded on cell phones or simple
                      digital cameras, for example. In addition, local libraries
                      and schools may be able to loan cameras to students. 
                    </div>
                  </div>
                  <div className="accordion__item">
                    <input type="checkbox" id="chck3"></input>
                    <label className="label" for="chck3">
                      What is the summer mentorship program?
                    </label>
                    <div className="content">
                      The top 10 Finalists will receive a summer assignment to
                      develop a prototype to be completed under the mentorship
                      of a 3M scientist. 
                    </div>
                  </div>
                  <div className="accordion__item">
                    <input type="checkbox" id="chck4"></input>
                    <label className="label" for="chck4">
                      Can students enter as pairs or teams?
                    </label>
                    <div className="content">
                      Students may not submit as pairs or in groups—only
                      individual submissions will be accepted.
                    </div>
                  </div>
                  <div className="accordion__item">
                    <input type="checkbox" id="chck5"></input>
                    <label className="label" for="chck5">
                      What happens at the Final Event?
                    </label>
                    <div className="content">
                      Ten student finalists will each give a presentation based
                      on their work in the summer mentorship program, and
                      participate in a series of scored challenges to
                      demonstrate their scientific knowledge. The event is
                      anticipated to take place in October 2025, but this date
                      is subject to change.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          <Column size={6} className="pb-6">
            <Image filename="image-about-FAQ.jpg" />
            <Link
              to="/annual-challenge/faqs"
              className="cta__link no-arrow bg-gradient-orange"
            >
              Read All FAQs <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Student Stories Quote */}
    <Section className="student-stories">
      <StudentQuote
        image="Laasya-Acharya-2020-Finalist-Quote.png"
        name="Laasya Acharya"
        year="2020 3M Young Scientist Challenge Finalist"
        body="For as long as I can remember, I’ve wanted to help people. I enjoy
      solving problems."
        path="/annual-challenge/finalists-mentors-judges/finalists/laasya-acharya"
        classNames="bg-gradient-orange cta__link no-arrow"
        colorbar="orange"
        calloutText="Learn what sparked Laasya's journey"
      ></StudentQuote>
    </Section>
  </Layout>
);

export default AboutTheChallenge;
