// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-anna-du-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/anna-du.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-anna-du-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-braeden-benedict-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/braeden-benedict.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-braeden-benedict-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-caroline-crouchley-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/caroline-crouchley.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-caroline-crouchley-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-devin-willis-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/devin-willis.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-devin-willis-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-faraz-tamboli-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/faraz-tamboli.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-faraz-tamboli-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-hannah-herbst-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/hannah-herbst.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-hannah-herbst-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-kaien-yang-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/kaien-yang.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-kaien-yang-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-katie-lampo-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/katie-lampo.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-katie-lampo-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-mehaa-amirthalingam-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/mehaa-amirthalingam.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-mehaa-amirthalingam-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-nicholas-lajoie-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/nicholas-lajoie.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-nicholas-lajoie-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-shanza-sami-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/shanza-sami.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-shanza-sami-js" */),
  "component---src-pages-alumni-center-alumni-spotlight-viraj-pandey-js": () => import("./../../../src/pages/alumni-center/alumni-spotlight/viraj-pandey.js" /* webpackChunkName: "component---src-pages-alumni-center-alumni-spotlight-viraj-pandey-js" */),
  "component---src-pages-alumni-center-overview-js": () => import("./../../../src/pages/alumni-center/overview.js" /* webpackChunkName: "component---src-pages-alumni-center-overview-js" */),
  "component---src-pages-annual-challenge-about-the-challenge-js": () => import("./../../../src/pages/annual-challenge/about-the-challenge.js" /* webpackChunkName: "component---src-pages-annual-challenge-about-the-challenge-js" */),
  "component---src-pages-annual-challenge-challenge-resources-js": () => import("./../../../src/pages/annual-challenge/challenge-resources.js" /* webpackChunkName: "component---src-pages-annual-challenge-challenge-resources-js" */),
  "component---src-pages-annual-challenge-entry-topics-js": () => import("./../../../src/pages/annual-challenge/entry-topics.js" /* webpackChunkName: "component---src-pages-annual-challenge-entry-topics-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-anatomy-of-challenge-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/anatomy-of-challenge.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-anatomy-of-challenge-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-importance-of-stem-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/importance-of-stem.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-importance-of-stem-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-innovation-taking-shape-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/innovation-taking-shape.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-innovation-taking-shape-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-journey-to-3-m-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/journey-to-3m.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-journey-to-3-m-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-life-of-scientist-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/life-of-scientist.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-life-of-scientist-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-meet-the-mentors-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/meet-the-mentors.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-meet-the-mentors-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-shaping-futures-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/shaping-futures.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-shaping-futures-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-the-importance-of-mentorship-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/the-importance-of-mentorship.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-the-importance-of-mentorship-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-top-young-scientist-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/top-young-scientist.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-top-young-scientist-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-voyage-of-ideas-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/voyage-of-ideas.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-voyage-of-ideas-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-who-inspired-you-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/who-inspired-you.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-who-inspired-you-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-why-science-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/why-science.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-why-science-js" */),
  "component---src-pages-annual-challenge-event-gallery-highlights-why-we-love-science-js": () => import("./../../../src/pages/annual-challenge/event-gallery/highlights/why-we-love-science.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-highlights-why-we-love-science-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2014-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2014-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2014-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2015-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2015-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2015-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2016-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2016-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2016-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2017-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2017-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2017-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2018-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2018-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2018-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2020-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2020-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2020-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2021-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2021-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2021-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2023-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2023-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2023-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2024-ysc-finalist-presentations-js": () => import("./../../../src/pages/annual-challenge/event-gallery/live-events/3M-2024-YSC-Finalist-Presentations.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-live-events-3-m-2024-ysc-finalist-presentations-js" */),
  "component---src-pages-annual-challenge-event-gallery-photos-js": () => import("./../../../src/pages/annual-challenge/event-gallery-photos.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-photos-js" */),
  "component---src-pages-annual-challenge-event-gallery-videos-js": () => import("./../../../src/pages/annual-challenge/event-gallery-videos.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-videos-js" */),
  "component---src-pages-annual-challenge-event-gallery-webinars-process-this-analyze-that-js": () => import("./../../../src/pages/annual-challenge/event-gallery/webinars/process-this-analyze-that.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-webinars-process-this-analyze-that-js" */),
  "component---src-pages-annual-challenge-event-gallery-webinars-storyboarding-successful-screen-debut-js": () => import("./../../../src/pages/annual-challenge/event-gallery/webinars/storyboarding-successful-screen-debut.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-webinars-storyboarding-successful-screen-debut-js" */),
  "component---src-pages-annual-challenge-event-gallery-webinars-ysc-webinar-2011-winner-braeden-benedict-js": () => import("./../../../src/pages/annual-challenge/event-gallery/webinars/ysc-webinar-2011-winner-braeden-benedict.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-webinars-ysc-webinar-2011-winner-braeden-benedict-js" */),
  "component---src-pages-annual-challenge-event-gallery-webinars-ysc-webinar-2012-winner-deepika-kurup-js": () => import("./../../../src/pages/annual-challenge/event-gallery/webinars/ysc-webinar-2012-winner-deepika-kurup.js" /* webpackChunkName: "component---src-pages-annual-challenge-event-gallery-webinars-ysc-webinar-2012-winner-deepika-kurup-js" */),
  "component---src-pages-annual-challenge-faqs-js": () => import("./../../../src/pages/annual-challenge/faqs.js" /* webpackChunkName: "component---src-pages-annual-challenge-faqs-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-and-events-alumni-news-js": () => import("./../../../src/pages/news-and-events/alumni-news.js" /* webpackChunkName: "component---src-pages-news-and-events-alumni-news-js" */),
  "component---src-pages-news-and-events-press-releases-js": () => import("./../../../src/pages/news-and-events/press-releases.js" /* webpackChunkName: "component---src-pages-news-and-events-press-releases-js" */),
  "component---src-pages-news-and-events-recent-news-js": () => import("./../../../src/pages/news-and-events/recent-news.js" /* webpackChunkName: "component---src-pages-news-and-events-recent-news-js" */),
  "component---src-pages-news-and-events-ysc-blog-js": () => import("./../../../src/pages/news-and-events/ysc-blog.js" /* webpackChunkName: "component---src-pages-news-and-events-ysc-blog-js" */),
  "component---src-pages-science-starters-getting-started-js": () => import("./../../../src/pages/science-starters/getting-started.js" /* webpackChunkName: "component---src-pages-science-starters-getting-started-js" */),
  "component---src-pages-science-starters-lesson-plans-js": () => import("./../../../src/pages/science-starters/lesson-plans.js" /* webpackChunkName: "component---src-pages-science-starters-lesson-plans-js" */),
  "component---src-pages-science-starters-science-at-home-bernoulli-balance-js": () => import("./../../../src/pages/science-starters/science-at-home/bernoulli-balance.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-bernoulli-balance-js" */),
  "component---src-pages-science-starters-science-at-home-c-2-balloon-js": () => import("./../../../src/pages/science-starters/science-at-home/c2-balloon.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-c-2-balloon-js" */),
  "component---src-pages-science-starters-science-at-home-diffusion-with-miss-america-2020-js": () => import("./../../../src/pages/science-starters/science-at-home/diffusion-with-miss-america-2020.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-diffusion-with-miss-america-2020-js" */),
  "component---src-pages-science-starters-science-at-home-elephant-toothpaste-js": () => import("./../../../src/pages/science-starters/science-at-home/elephant-toothpaste.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-elephant-toothpaste-js" */),
  "component---src-pages-science-starters-science-at-home-feeling-sound-js": () => import("./../../../src/pages/science-starters/science-at-home/feeling-sound.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-feeling-sound-js" */),
  "component---src-pages-science-starters-science-at-home-fidget-spinner-js": () => import("./../../../src/pages/science-starters/science-at-home/fidget-spinner.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-fidget-spinner-js" */),
  "component---src-pages-science-starters-science-at-home-how-do-we-breathe-js": () => import("./../../../src/pages/science-starters/science-at-home/how-do-we-breathe.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-how-do-we-breathe-js" */),
  "component---src-pages-science-starters-science-at-home-inflation-station-js": () => import("./../../../src/pages/science-starters/science-at-home/inflation-station.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-inflation-station-js" */),
  "component---src-pages-science-starters-science-at-home-js": () => import("./../../../src/pages/science-starters/science-at-home.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-js" */),
  "component---src-pages-science-starters-science-at-home-liquid-fireworks-js": () => import("./../../../src/pages/science-starters/science-at-home/liquid-fireworks.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-liquid-fireworks-js" */),
  "component---src-pages-science-starters-science-at-home-make-your-own-cotton-ball-launcher-js": () => import("./../../../src/pages/science-starters/science-at-home/make-your-own-cotton-ball-launcher.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-make-your-own-cotton-ball-launcher-js" */),
  "component---src-pages-science-starters-science-at-home-marshmallow-tower-js": () => import("./../../../src/pages/science-starters/science-at-home/marshmallow-tower.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-marshmallow-tower-js" */),
  "component---src-pages-science-starters-science-at-home-push-and-pull-js": () => import("./../../../src/pages/science-starters/science-at-home/push-and-pull.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-push-and-pull-js" */),
  "component---src-pages-science-starters-science-at-home-rainbow-bubble-snake-js": () => import("./../../../src/pages/science-starters/science-at-home/rainbow-bubble-snake.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-rainbow-bubble-snake-js" */),
  "component---src-pages-science-starters-science-at-home-soap-boat-js": () => import("./../../../src/pages/science-starters/science-at-home/soap-boat.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-home-soap-boat-js" */),
  "component---src-pages-science-starters-science-at-work-jeff-emslander-js": () => import("./../../../src/pages/science-starters/science-at-work/jeff-emslander.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-work-jeff-emslander-js" */),
  "component---src-pages-science-starters-science-at-work-js": () => import("./../../../src/pages/science-starters/science-at-work.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-work-js" */),
  "component---src-pages-science-starters-science-at-work-sara-frisco-js": () => import("./../../../src/pages/science-starters/science-at-work/sara-frisco.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-work-sara-frisco-js" */),
  "component---src-pages-science-starters-science-at-work-tesha-alston-dampier-js": () => import("./../../../src/pages/science-starters/science-at-work/tesha-alston-dampier.js" /* webpackChunkName: "component---src-pages-science-starters-science-at-work-tesha-alston-dampier-js" */),
  "component---src-pages-science-starters-virtual-field-trips-how-nature-inspires-3-m-science-js": () => import("./../../../src/pages/science-starters/virtual-field-trips/how-nature-inspires-3M-science.js" /* webpackChunkName: "component---src-pages-science-starters-virtual-field-trips-how-nature-inspires-3-m-science-js" */),
  "component---src-pages-science-starters-virtual-field-trips-inside-the-world-of-science-js": () => import("./../../../src/pages/science-starters/virtual-field-trips/inside-the-world-of-science.js" /* webpackChunkName: "component---src-pages-science-starters-virtual-field-trips-inside-the-world-of-science-js" */),
  "component---src-pages-science-starters-virtual-field-trips-js": () => import("./../../../src/pages/science-starters/virtual-field-trips.js" /* webpackChunkName: "component---src-pages-science-starters-virtual-field-trips-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-judge-js": () => import("./../../../src/templates/Judge.js" /* webpackChunkName: "component---src-templates-judge-js" */),
  "component---src-templates-judges-js": () => import("./../../../src/templates/Judges.js" /* webpackChunkName: "component---src-templates-judges-js" */),
  "component---src-templates-mentor-js": () => import("./../../../src/templates/Mentor.js" /* webpackChunkName: "component---src-templates-mentor-js" */),
  "component---src-templates-mentors-js": () => import("./../../../src/templates/Mentors.js" /* webpackChunkName: "component---src-templates-mentors-js" */),
  "component---src-templates-student-js": () => import("./../../../src/templates/Student.js" /* webpackChunkName: "component---src-templates-student-js" */),
  "component---src-templates-students-js": () => import("./../../../src/templates/Students.js" /* webpackChunkName: "component---src-templates-students-js" */)
}

