import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useLocation, globalHistory } from '@reach/router';
import classNames from 'classnames';
import { Container } from '../Grid';
import Share from '../Share';
//import Subscribe from '../Subscribe';
import OutboundLink from '../OutboundLink';
import Button from '../Button';
import Image from '../Image';
import Icon from '../Icon';
import Modal from '../Modal';
import './styles.scss';

/**
 * A global header component that is populated with properties from `gatsby-config.js`
 */

const Header = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isOffPage, setIsOffPage] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(
    typeof window !== `undefined` ? window.innerWidth : null
  );

  const header = useRef(null);
  const headerUpper = useRef(null);
  const headerLower = useRef(null);
  const logo = useRef(null);
  const menu = useRef(null);

  const { pathname: currentPath } = useLocation();

  const isHome = currentPath === '/';

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          partnerName
          partnerLogo
          programName
          programLogo
          menuLinks {
            label
            path
            children {
              label
              path
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const threshold = 80;
    let ticking = false;
    let lastScrollY = window.pageYOffset;

    const mobileMenuHeight = isMobileMenuVisible
      ? menu.current.offsetHeight
      : 0;
    const headerHeight = header.current.offsetHeight + mobileMenuHeight;
    const headerUpperStyles = window.getComputedStyle(header.current);
    const headerUpperPaddingTop = parseInt(
      headerUpperStyles.getPropertyValue('padding-top'),
      10
    );

    const stickyHeaderOffset =
      headerUpper.current.offsetHeight + headerUpperPaddingTop;

    if (isSticky) {
      header.current.style.transform = `translateY(-${stickyHeaderOffset}px)`;
    } else {
      header.current.style.transform = '';
    }

    const toggleHeader = () => {
      const scrollY = window.pageYOffset;

      if (scrollY > headerHeight) {
        if (!isOffPage) {
          setIsOffPage(true);
          setActiveSubMenu(null);
        }
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);
      }

      if (
        scrollY > headerHeight &&
        Math.abs(scrollY - lastScrollY) < threshold
      ) {
        ticking = false;
        return;
      }

      if (
        (scrollY <= stickyHeaderOffset || scrollY > lastScrollY) &&
        isSticky
      ) {
        setIsSticky(false);
        if (scrollY <= stickyHeaderOffset) {
          if (isOffPage) setIsOffPage(false);
        }
      } else if (
        scrollY > stickyHeaderOffset &&
        scrollY < lastScrollY &&
        !isSticky
      ) {
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);
        setIsSticky(true);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(toggleHeader);
        ticking = true;
      }
    };

    const handleResize = () => {
      if (typeof window !== `undefined`) {
        setViewportWidth(window.innerWidth);

        if (window.innerWidth >= 1200 && isMobileMenuVisible) {
          setIsMobileMenuVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          setIsOffPage(false);
          setIsSticky(false);
          setIsMobileMenuVisible(false);
        }
      });
    };
  }, [isSticky, isOffPage, isMobileMenuVisible, viewportWidth]);

  const handleHamburgerClick = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleSubmenuToggle = (e, path) => {
    e.preventDefault();
    setActiveSubMenu((currentPath) => (currentPath === path ? null : path));
  };

  const renderMainMenu = (links, parent = null) => {
    return (
      <ul
        className={classNames(
          parent ? 'header__main-menu-sublist' : 'header__main-menu-list',
          parent && links[links.length - 1]
            ? 'header__main-menu-sublist-last'
            : 'header__main-menu-list',
          parent &&
            activeSubMenu === parent &&
            'header__main-menu-sublist--open'
        )}
      >
        {links.map((link, i) => (
          <li
            key={i}
            className={classNames(
              parent
                ? 'header__main-menu-sublist-item'
                : 'header__main-menu-list-item',
              activeSubMenu === link.path &&
                (parent
                  ? 'header__main-menu-sublist-item--active'
                  : 'header__main-menu-list-item--active')
            )}
          >
            <Link
              activeClassName="header__main-menu-link--active"
              to={link.path}
              onClick={
                link.children && ((e) => handleSubmenuToggle(e, link.path))
              }
              className={
                parent
                  ? 'header__main-menu-sublist-link'
                  : 'header__main-menu-link'
              }
            >
              {link.label}{' '}
              {link.children && (
                <Icon
                  name="caratdown"
                  className={classNames(
                    'header__main-menu-carat',
                    activeSubMenu === link.path &&
                      'header__main-menu-carat--rotated'
                  )}
                  marginLeft
                />
              )}
            </Link>
            {link.children && renderMainMenu(link.children, link.path)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <header
      className={classNames('header', isOffPage && 'header--off-page')}
      ref={header}
    >
      {/* Updates/Share links */}
      <div
        className={classNames('header__upper', isHome && 'header__upper--home')}
        ref={headerUpper}
        style={{ marginTop: '1rem' }}
      >
        <Container fullWidth style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="header__upper-content">
            <div className="de-logo mt-1 ml-0.5">
              <Image filename="de-header-logo.png" />
            </div>
            <div className="header__utility-menu">
              <div className="header__signup">
                <OutboundLink to="https://info.discoveryeducation.com/YoungScientistLab.html">
                  <Button className="button button--link share__button" link>
                    Updates
                    <Icon name="mailiconsmallgray" />
                  </Button>
                </OutboundLink>
              </div>
              <div className="header__share">
                <Share inDropDown />
              </div>
              {/* Hide Challenge Application Portal Button for Challenge Close */}
              {/* <div className="header__modal">
                <Modal
                  trigger={
                    <div className="header__register">
                      <Button className="header__register-button" link>
                        Challenge Application Portal{' '}
                        <Icon name="externallink"></Icon>
                      </Button>
                    </div>
                  }
                >
                  <div className="header__modal-content">
                    <div className="header__modal-copy">
                      <p>
                        You are now leaving&nbsp;
                        <Link to="/">Young Scientist Lab</Link> and being
                        directed to&nbsp; <br></br>
                        <OutboundLink to="https://webportalapp.com/sp/login/2024_3myoungscientistchallenge">
                          https://webportalapp.com/sp/login/2024_3myoungscientistchallenge
                        </OutboundLink>
                      </p>
                      <p>
                        The policies of the site to which you are being directed
                        will apply.
                      </p>
                      <div className="header__modal-button-wrap">
                        <OutboundLink
                          to="https://webportalapp.com/sp/login/2024_3myoungscientistchallenge"
                          target="_blank"
                        >
                          <Button className="bg-gradient-orange">
                            Continue <Icon name="externallink"></Icon>
                          </Button>
                        </OutboundLink>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div> */}
              {/*  */}
            </div>
          </div>
          <a className='header__app-portal' href='https://dlc.com/3MYSC-Application' target='_blank'>
                Challenge Application Portal
          </a>
        </Container>
      </div>
      <div className="header__lower" ref={headerLower}>
        <Container fullWidth>
          <div className="header__lower-content">
            <div className="header__program-logo" ref={logo}>
              <Link to="/">
                <Image
                  filename={site.siteMetadata.programLogo}
                  alt={site.siteMetadata.programName}
                />
              </Link>
            </div>
            <div className="header__hamburger">
              <Button
                link
                className={classNames(
                  'header__hamburger-button',
                  isMobileMenuVisible && 'header__hamburger-button--active',
                  isSticky && 'header__hamburger-button--in-sticky'
                )}
                onClick={handleHamburgerClick}
              >
                <div className="header__hamburger-button-inner"></div>
              </Button>
            </div>
            <div
              className={classNames(
                'header__collapse',
                isMobileMenuVisible && 'header__collapse--visible'
              )}
              ref={menu}
            >
              <nav className="header__main-menu">
                {renderMainMenu(site.siteMetadata.menuLinks)}
              </nav>
              {/** Hamburger submenu buttons */}
              <div
                className={classNames(
                  'header__hamburger-bottom-content',
                  !isMobileMenuVisible &&
                    'header__hamburger-bottom-content--hide'
                )}
              >
                <div className="header__hamburger-social-container">
                  <OutboundLink to="https://info.discoveryeducation.com/YoungScientistLab.html">
                    <Button
                      className="button button--link header__hamburger-updates-button"
                      link
                    >
                      <span className="header__hamburger-update-text">
                        Updates
                      </span>
                      <Icon name="mailiconsmallgray" />
                    </Button>
                  </OutboundLink>
                </div>
                <div className="header__share header__hamburger-social-container">
                  <Share
                    shareLinkClass="header__hamburger-share-link"
                    shareLinkLabelClass="header__hamburger-share-link-label"
                  />
                </div>
                <div className="header__modal">
                  <Modal
                    trigger={
                      <div className="header__register">
                        <Button className="header__register-button" link>
                          Challenge Application Portal{' '}
                          <Icon name="externallink"></Icon>
                        </Button>
                      </div>
                    }
                  >
                    <div className="header__modal-content">
                      <div className="header__modal-copy">
                        <p>
                          You are now leaving&nbsp;
                          <Link to="/">Young Scientist Lab</Link> and being
                          directed to&nbsp; <br></br>
                          <OutboundLink to="https://webportalapp.com/sp/login/2024_3myoungscientistchallenge">
                            https://webportalapp.com/sp/login/2024_3myoungscientistchallenge
                          </OutboundLink>
                        </p>
                        <p>
                          The policies of the site to which you are being
                          directed will apply.
                        </p>
                        <div className="header__modal-button-wrap">
                          <OutboundLink
                            to="https://webportalapp.com/sp/login/2024_3myoungscientistchallenge"
                            target="_blank"
                          >
                            <Button className="bg-gradient-orange">
                              Continue <Icon name="externallink"></Icon>
                            </Button>
                          </OutboundLink>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
              {/** End Hamburger submenu buttons */}
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
