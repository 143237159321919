import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import OutboundLink from '../../components/OutboundLink';
import Image from '../../components/Image';
import Quicklinks from '../../components/Quicklinks';
import Icon from '../../components/Icon';

const EntryTopics = () => (
  <Layout title="Entry Topics" className="annual-challenge">
    {/* Convert to proper Hero Component */}
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-orange"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../annual-challenge/about-the-challenge/">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb active">Entry Topics</li>
        </ul>
        <h1 className="page-title">Entry Topics</h1>
        <Image filename="hero-problems-to-solve.png" className="hero-image" />
      </div>
    </div>
    {/* Quick Links */}
    <Section className="pt-1 pb-1">
      <Container>
        <Row>
          <Column size={11} offset={1}>
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#about',
                  label: 'About',
                },
                {
                  target: '#robotics',
                  label: 'Robotics',
                },
                {
                  target: '#home-improvement',
                  label: 'Home Improvement',
                },
                {
                  target: '#automotive',
                  label: 'Automotive',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#ar-vr',
                  label: 'AR/VR',
                },
                {
                  target: '#climate-tech',
                  label: 'Climate Tech',
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Improving Lives with Science */}
    <Section id="about">
      <Container>
        <Row>
          <Column size={12}>
            <h2>Change for Good</h2>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <Image filename="improving-lives-video-promo.jpg" />
          </Column>
          <Column size={6} className="global-intro pb-6">
            <h4>
              Many of the greatest innovations of our time were first designed
              to solve simple problems for which we didn’t even know we needed
              solutions. 
            </h4>
            <p className="text-18 pr-6">
              The 3M Young Scientist Challenge encourages young people to solve
              everyday problems using science and innovation–and we can’t wait
              to see what you dream up! 
            </p>
            <OutboundLink
              to="https://dlc.com/3MYSC-Application"
              className="cta__link no-arrow bg-gradient-orange"
            >
              Register for the Challenge <Icon name="arrowright" />
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Robotics Section */}
    <Section id="robotics" className="mb-3 entry-topics">
      <Container>
        <Row>
          <Column size={12}>
            <h2>Entry Topic Areas</h2>
          </Column>
        </Row>
        <Row className="mb-1">
          <Column size={2} offset={1}>
            <Image filename="3M-YSC-icon-Robotics.svg" className="pts-icon" />
          </Column>
          <Column size={6} className="negative-margin">
            <h3>Robotics</h3>
            <p>
              Robotics can revolutionize the way we live our everyday lives.
              These machines, built and programmed to perform tasks without
              human interference, have made it possible to perform tasks with
              incredible speed and accuracy. How do you think the power of
              robotics could impact your community?
            </p>
          </Column>
        </Row>
        <Row className="mb-3">
          <Column size={5} offset={1}>
            <Image filename="3M-YSC-topic-image-robotics.jpg" />
          </Column>
          <Column size={5} className="pb-5">
            <h4 className="mb-0.5">What kind of innovation would...</h4>
            <ul className="mb-0">
              <li>
                Harness the power of robotics for positive change in our
                everyday lives?
              </li>
              <li>Increase productivity using robotics?</li>
              <li>Utilize robotics to improve businesses?</li>
              <li>
                Use robotics to improve people’s experiences at schools, homes,
                offices, or factories?
              </li>
              <li>Use robotics to improve safety measures?</li>
            </ul>
            <Link
              to="/annual-challenge/challenge-resources"
              className="cta__link bg-gradient-orange"
            >
              Explore Getting Started Resources <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
        <Row>
          <Column size={10} offset={1}>
            <h4>
              For inspiration, take a look at these 3M Young Scientist Challenge
              Alumni:
            </h4>
          </Column>
        </Row>
        <Row>
          <Column size={2} offset={1}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/samhita-pokkunuri-2020-finalist">
              <Image filename="Samhita-Pokkunuri-2020-Finalist-Card.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/samhita-pokkunuri-2020-finalist">
              <p>
                <span className="text-bold">Samhita Pokkunuri</span> | Robotic
                Swarms: Intelligent Network of Robots Performing Cooperative
                Tasks
              </p>
            </Link>
          </Column>
          <Column size={2}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/ankan-das">
              <Image filename="Finalist-Headshot-CARD_435x264_Ankan-Das.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/ankan-das">
              <p>
                <span className="text-bold">Ankan Das</span> | A Shape Memory
                Alloy Based Intelligent Walking Robot For Inspection and
                Surveillance
              </p>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Home Section */}
    <Section id="home-improvement" className="mb-3 entry-topics">
      <Container>
        <Row className="mb-1">
          <Column size={2} offset={1}>
            <Image filename="3M-YSC-icon-Home.svg" className="pts-icon" />
          </Column>
          <Column size={6} className="negative-margin">
            <h3>Home Improvement</h3>
            <p>
              Science can help us make our homes more energy efficient as well
              as safer and more comfortable for ourselves and our families.
              Think of some of the positive changes that could be made to homes
              through scientific innovation.
            </p>
          </Column>
        </Row>
        <Row className="mb-3">
          <Column size={5} offset={1}>
            <Image filename="3M-YSC-topic-image-home.jpg" />
          </Column>
          <Column size={5} className="pb-5">
            <h4 className="mb-0.5">What kind of innovation would...</h4>
            <ul className="mb-0">
              <li>Reduce damage to homes during natural disasters?</li>
              <li>Improve plumbing in homes?</li>
              <li>Make air filtration in homes more energy efficient?</li>
              <li>Advance security systems in homes?</li>
              <li>Improve electrical systems in homes?</li>
              <li>Make homes safer for young children?</li>
              <li>More quickly and more efficiently make repairs in homes?</li>
            </ul>
            <Link
              to="/annual-challenge/challenge-resources"
              className="cta__link bg-gradient-orange"
            >
              Explore Getting Started Resources <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
        <Row>
          <Column size={10} offset={1}>
            <h4>
              For inspiration, take a look at these 3M Young Scientist Challenge
              Alumni:
            </h4>
          </Column>
        </Row>
        <Row>
          <Column size={2} offset={1}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/daniel-thomas">
              <Image filename="Daniel-Thomas-2022-Finalist-Card.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/daniel-thomas">
              <p>
                <span className="text-bold">Daniel Thomas</span> | A Novel
                Dosing Pump to Prevent Clogs and Organic Overgrowth in AC
                Condensate Lines
              </p>
            </Link>
          </Column>
          <Column size={2}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/mehaa-amirthalingam">
              <Image filename="Mehaa-Amirthalingam-2018-Finalist-Card.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/mehaa-amirthalingam">
              <p>
                <span className="text-bold">Mehaa Amirthalingam</span> | A Novel
                Toilet Flushing System using Grey Water and Fresh Water
              </p>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Automotive Section */}
    <Section id="automotive" className="mb-3 entry-topics">
      <Container>
        <Row className="mb-1">
          <Column size={2} offset={1}>
            <Image filename="3M-YSC-icon-Auto.svg" className="pts-icon" />
          </Column>
          <Column size={6} className="negative-margin">
            <h3>Automotive</h3>
            <p>
              Cars are essential to many of our lives, as they allow us to move
              from place to place when public transportation is not an option.
              Scientists are finding new ways to make the automotive industry
              more efficient and sustainable while improving car manufacturing
              and design.
            </p>
          </Column>
        </Row>
        <Row className="mb-3">
          <Column size={5} offset={1}>
            <Image filename="3M-YSC-topic-image-auto.jpg" />
          </Column>
          <Column size={5} className="pb-5">
            <h4 className="mb-0.5">What kind of innovation would...</h4>
            <ul className="mb-0">
              <li>Make cars more energy efficient?</li>
              <li>Improve the capacity of electric vehicles?</li>
              <li>Improve safety in cars?</li>
              <li>Help prevent car accidents?</li>
              <li>Advance car repairs?</li>
              <li>Help prevent car theft?</li>
              <li>Make car assembly and manufacturing more efficient?</li>
            </ul>
            <Link
              to="/annual-challenge/challenge-resources"
              className="cta__link bg-gradient-orange"
            >
              Explore Getting Started Resources <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
        <Row>
          <Column size={10} offset={1}>
            <h4>
              For inspiration, take a look at these 3M Young Scientist Challenge
              Alumni:
            </h4>
          </Column>
        </Row>
        <Row>
          <Column size={2} offset={1}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/conner-pettit">
              {/* <Image filename="Camden-Hatch-2015-State-Merit-Winner-Card.jpg" /> */}
              <Image filename="Conner-Pettit-2015-Finalist-Card.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/conner-pettit">
              <p>
                <span className="text-bold">Conner Pettit</span> | Car
                Temperature Sensor
              </p>
            </Link>
          </Column>
          <Column size={2}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/asvini-thivakaran">
              <Image filename="Asvini-Thivakaran-2022-Finalist-Card.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/asvini-thivakaran">
              <p>
                <span className="text-bold">Asvini Thivakaran</span> |
                Piezoelectric Power Generation from Automotive Tires
              </p>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Safety Section */}
    <Section id="safety" className="mb-3 entry-topics">
      <Container>
        <Row className="mb-1">
          <Column size={2} offset={1}>
            <Image filename="3M-YSC-icon-Safety.svg" className="pts-icon" />
          </Column>
          <Column size={6} className="negative-margin">
            <h3>Safety</h3>
            <p>
              Whether you’re on the sports field or crossing the street, safety
              should always come first. Through science, we can improve safety
              measures in our everyday lives and help make the things we do and
              consume safer and cleaner for everyone.
            </p>
          </Column>
        </Row>
        <Row className="mb-3">
          <Column size={5} offset={1}>
            <Image filename="3M-YSC-topic-image-safety.jpg" />
          </Column>
          <Column size={5} className="pb-5">
            <h4 className="mb-0.5">What kind of innovation would...</h4>
            <ul className="mb-0">
              <li>Prevent young children from accidental injuries?</li>
              <li>
                Reduce the risk of injury to workers on construction sites?{' '}
              </li>
              <li>Advance the tools used to heal from a sports injury?</li>
              <li>Prevent identity theft?</li>
              <li>Make buildings more durable or reliable?</li>
              <li>
                Find new ways to make roads safer for motorists, bicyclists,
                pedestrians, and road workers.
              </li>
              <li>Improve safety in your community?</li>
              <li>Improve safety in schools?</li>
              <li>Explore the best way to improve indoor air quality.</li>
            </ul>
            <Link
              to="/annual-challenge/challenge-resources"
              className="cta__link bg-gradient-orange"
            >
              Explore Getting Started Resources <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
        <Row>
          <Column size={10} offset={1}>
            <h4>
              For inspiration, take a look at these 3M Young Scientist Challenge
              Alumni:
            </h4>
          </Column>
        </Row>
        <Row>
          <Column size={2} offset={1}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/gitanjali-rao">
              <Image filename="Gitanjali-Rao-2017-Winner-Card.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/gitanjali-rao">
              <p>
                <span className="text-bold">Anish Kosaraju</span> | A Novel
                Machine Learning Approach to Preventing Account Takeovers for
                Enhanced Cybersecurity
              </p>
            </Link>
          </Column>
          <Column size={2}>
            <Link to="annual-challenge/finalists-mentors-judges/finalists/sirish-subash">
              <Image filename="Finalist-Headshot-CARD_435x264_Sirish-Subash.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/sirish-subash">
              <p>
                <span className="text-bold">Sirish Subash</span> | Pestiscand –
                An AI-based Handheld Pesticide Detector
              </p>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* AR/VR Section */}
    <Section id="ar-vr" className="mb-3 entry-topics">
      <Container>
        <Row className="mb-1">
          <Column size={2} offset={1}>
            <Image filename="3M-YSC-icon-AV-VR.svg" className="pts-icon" />
          </Column>
          <Column size={6} className="negative-margin">
            <h3>AR/VR</h3>
            <p>
              Augmented Reality (AR) and Virtual Reality (VR) are important
              technologies, because they have the power to change how we live,
              work, and interact with one another. How can these revolutionary
              technologies be used for positive change?
            </p>
          </Column>
        </Row>
        <Row className="mb-3">
          <Column size={5} offset={1}>
            <Image filename="3M-YSC-topic-image-AR-VR.jpg" />
          </Column>
          <Column size={5} className="pb-5">
            <h4 className="mb-0.5">What kind of innovation would...</h4>
            <ul className="mb-0">
              <li>Use AR/VR to increase efficiency and productivity? </li>
              <li>Help businesses using AR/VR?</li>
              <li>
                Utilize AR/VR to improve experiences in schools and support
                education?
              </li>
              <li>
                Harness AR/VR to improve safety measures in our everyday lives?
              </li>
            </ul>
            <Link
              to="/annual-challenge/challenge-resources"
              className="cta__link bg-gradient-orange"
            >
              Explore Getting Started Resources <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
        <Row>
          <Column size={10} offset={1}>
            <h4>
              For inspiration, take a look at these 3M Young Scientist Challenge
              Alumni:
            </h4>
          </Column>
        </Row>
        <Row>
          <Column size={2} offset={1}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/sean-jiang">
              <Image filename="Sean_Jiang_Finalist Headshot-CARD_435x264_1x.png" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/winners/sean-jiang">
              <p>
                <span className="text-bold">Sean Jiang</span> | Sonic Mapping:
                Using a Depth Camera and Audio Cues to Assist the Blind in
                Navigation
              </p>
            </Link>
          </Column>

          <Column size={2}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/hanna-suzuki-2024">
              <Image filename="Finalist-Headshot-CARD_435x264_Hanna-Suzuki.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/hanna-suzuki-2024">
              <p>
                <span className="text-bold">Hanna Suzuki</span> | Audio
                Augmentation for Celestial Objects: Sonifying Constellations and
                Mapping their Sound Logos onto the Night Sky
              </p>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Climate Section */}
    <Section id="climate-tech" className="mb-3 entry-topics">
      <Container>
        <Row className="mb-1">
          <Column size={2} offset={1}>
            <Image filename="3M-YSC-icon-Climate.svg" className="pts-icon" />
          </Column>
          <Column size={6} className="negative-margin">
            <h3>Climate Tech</h3>
            <p>
              Our planet holds precious resources, and it is our responsibility
              to properly manage them so future generations can enjoy the same
              quality of life that we do. As we look to tomorrow, many
              scientists are harnessing science-based innovations that have the
              potential to tackle climate-related challenges.
            </p>
          </Column>
        </Row>
        <Row className="mb-3">
          <Column size={5} offset={1}>
            <Image filename="3M-YSC-topic-image-climate.jpg" />
          </Column>
          <Column size={5} className="pb-5">
            <h4 className="mb-0.5">What kind of innovation would...</h4>
            <ul className="mb-0">
              <li>Combat rising temperatures around the globe?</li>
              <li>Make drinking water cleaner for people across the world?</li>
              <li>Reduce your carbon footprint?</li>
              <li>Improve air quality?</li>
              <li>Create new ways to use recycled materials?</li>
              <li>Clean up our oceans and forests for wildlife?</li>
              <li>Build more products with less material?</li>
              <li>Make it easier for people to recycle?</li>
              <li>Lower the cost of sustainable technology?</li>
            </ul>
            <Link
              to="/annual-challenge/challenge-resources"
              className="cta__link bg-gradient-orange"
            >
              Explore Getting Started Resources <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
        <Row>
          <Column size={10} offset={1}>
            <h4>
              For inspiration, take a look at these 3M Young Scientist Challenge
              Alumni:
            </h4>
          </Column>
        </Row>
        <Row>
          <Column size={2} offset={1}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/anisha-dhoot">
              <Image filename="Anisha_Finalist Headshot-CARD_435x264_1x.png" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/anisha-dhoot">
              <p>
                <span className="text-bold">Anisha Dhoot</span> | Using Kelp
                Seaweed to Improve Food Security and Fight Climate Change:
                Building Soil Parametric Database and Exploring AI/ML
                Application to Improve Productivity and Reduce Carbon Footprint
              </p>
            </Link>
          </Column>
          <Column size={2}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/minula-weerasekera-2024">
              <Image filename="Finalist-Headshot-CARD_435x264_Minula-Weerasekera.jpg" />
            </Link>
          </Column>
          <Column size={3}>
            <Link to="/annual-challenge/finalists-mentors-judges/finalists/minula-weerasekera-2024">
              <p>
                <span className="text-bold">Minula Weerasekera</span> | Creating
                an Organic Redox Battery Efficiencies
              </p>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default EntryTopics;
