import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2023 = () => (
  <Layout
    title="3M 2023 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2023 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2023 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: '4b58aeeb-71ca-4ddd-8ff2-81b99499ceb2',
                    poster: '2023-finalist-thumb-adhip-maitra.png',
                    title: '3M 2023 YSC Finalist Presentation: Adhip Maitra',
                    description:
                      'Meet Adhip. Adhip is finding effective ways to remove carbonates from ocean water to lower acidity.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '2a27af7d-40a6-48c2-b6f0-d9ebdf5a5031',
                    poster: '2023-finalist-thumb-anish-kosaraju.png',
                    title: '3M 2023 YSC Finalist Presentation: Anish Kosaraju',
                    description:
                      'Meet Anish. Anish built a new method to improve cybersecurity measures that does not require Multi-Factor Authentication to combat account takeovers.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '86b62667-65f4-4e18-8c7d-e69012a7abfe',
                    poster: '2023-finalist-thumb-anisha-dhoot.png',
                    title: '3M 2023 YSC Finalist Presentation: Anisha Dhoot',
                    description:
                      'Meet Anisha. Anisha’s project focuses on utilizing kelp seaweed to improve soil quality and crop selection.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'd6708312-fc38-496a-9fbc-9a51f4d442ea',
                    poster: '2023-finalist-thumb-annie-katz.png',
                    title: '3M 2023 YSC Finalist Presentation: Annie Katz',
                    description:
                      'Meet Annie. Annie created an affordable in-home ultrasound device and detection programming that can identify and grade hamstring lesions.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '634d2e9a-b3ce-45a9-abe3-57cded74cb95',
                    poster: '2023-finalist-thumb-heman-bekele.png',
                    title: '3M 2023 YSC Finalist Presentation: Heman Bekele',
                    description:
                      'Meet Heman. Heman developed Melanoma Treating Soap (MTS), a compound-based bar of soap designed to treat skin cancer.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'c6c02d50-a5d3-4378-b34e-c50a39e3964f',
                    poster: '2023-finalist-thumb-ishaan-iyer.png',
                    title: '3M 2023 YSC Finalist Presentation: Ishaan Iyer',
                    description:
                      'Meet Ishaan. Ishaan created a cost-effective Braille device that encodes the English alphabet into Braille language and converts it into a tactile Braille cell.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'c6448d86-dc81-4bf7-946d-3481ceabf74e',
                    poster: '2023-finalist-thumb-sarah-wang.png',
                    title: '3M 2023 YSC Finalist Presentation: Sarah Wang',
                    description:
                      'Meet Sarah. Sarah developed The Spring Epilepsy Detection Glove, a glove that can detect tonic-clonic and myoclonic epileptic seizures with common hand movements. The Spring Epilepsy Detection Glove connects with a smartphone app to track seizure statistics.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '3db5ad4b-e652-4b0c-a3e6-97823a9bd74d',
                    poster: '2023-finalist-thumb-sean-jiang.png',
                    title: '3M 2023 YSC Finalist Presentation: Sean Jiang',
                    description:
                      'Meet Sean. Sean’s innovation uses a depth camera and auditory device to assist blind individuals in navigation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'ce446237-0cd8-4939-9c4e-14c15c8b641b',
                    poster: '2023-finalist-thumb-shripriya-kalbhavi.png',
                    title:
                      '3M 2023 YSC Finalist Presentation: Shripriya Kalbhavi',
                    description:
                      'Meet Shripriya. She developed EasyBZ, a cost-effective microneedle patch that allows for self-automated drug delivery without pills or needles.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '015394aa-ebcf-418d-b613-707d2dc1a15d',
                    poster: '2023-finalist-thumb-shruti-sivaraman.png',
                    title:
                      '3M 2023 YSC Finalist Presentation: Shruti Sivaraman',
                    description:
                      'Meet Shruti. Shruti created a low-cost device and app that detects and diagnoses diabetic retinopathy (DR) by taking retinal images without the use of dilation drops.',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2020-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2023 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2023;
